import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

interface ContactData {
  name: string;
  address: string;
  phone: string;
  mobilePhone: string;
  email: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
  }

}
